<template>
  <div style="background-color: #84a7d0; width: 100%; height: 20vh; margin-bottom: 3rem; display: flex; flex-direction: column; justify-content: center; align-items: center">
    <div class="cuadro-busqueda col-md-4">
      <form class="input-group" @submit.prevent="getCompany()">
        <input type="search" class="form-control" v-model="searchInput" placeholder="Buscar por título de vacante..." />
      </form>
    </div>
  </div>
  <div class="container-view-vac">
    <div class="tarjeta-vista">
      <div class="card mb-3 style-card" v-for="(item, i) in listVacantes" :key="i + 1" v-show="filter(item)">
        <div class="foto" style="flex: 1; display: flex; justify-content: center; align-items: center">
          <img
            v-if="!item.vacante.private"
            :src="item.userCompany.photo ? item.userCompany.photo.link : imageUrl"
            class="rounded-circle photo-company"
            style="aspect-ratio: 1/1; object-fit: cover; object-position: center"
            alt="Logo Company" />
          <img v-else src="/images/profile.jpg" alt="Logo Company" class="rounded-circle" style="width: 90%; aspect-ratio: 1/1; object-fit: cover; object-position: center" />
        </div>
        <div class="card-body cuerpo-tarjeta">
          <div class="info-company-text">
            <span v-if="item.vacante.private">EMPRESA CONFIDENCIAL</span>
            <span v-else>{{ item.nameCompany }}</span>
          </div>
          <div>
            <span>"{{ item.vacante.titulo }}"</span>
            <div class="ubicacion-icon">
              <span style="margin-left: 2px; text-transform: capitalize">
                <small v-show="item.vacante.ubicacion">{{ item.vacante.ubicacion }}, </small>
                <small v-show="item.vacante.estado"> {{ item.vacante.estado }} </small>
              </span>
            </div>
            <!-- <span class="card-text-public fst-italic">
                            Publicada el {{ moment(item.vacante.createdAt).format('DD/MM/YYYY') }}
                        </span> -->
          </div>
          <div class="J-talento-btn-A mt-3">
            <button class="btn-b" @click="viewregister2()">
              <span class="txt-btn-a"> Postularme ahora </span>
            </button>
            <button class="btn-b" v-on:click="linkVacante(item.vacante._id)">
              <span class="txt-btn-a"> Ver Detalles </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { defineComponent } from "vue";
import universityService from "../services/university.service";

export default defineComponent({
  data: () => ({
    moment: moment,
    search: "",
    searchInput: "",
    listVacantes: [],
    imageUrl: "/images/profile.jpg",
  }),
  created() {
    this.search = this.$route.params.slug;
    console.log(this.search);
    this.getUniversityBySlug(this.search);
    this.getVacantesForUniversity(this.search);
  },
  methods: {
    async getUniversityBySlug() {
      try {
        const resp = await universityService.getUniversityBySlug({ slug: this.search });
        const { data } = resp.data;
        this.listVacantes = data;
        if (this.listVacantes === null) {
          this.$router.push('/upcoming');
        }
      } catch (error) {
        console.error("Error al obtener la universidad:", error);
        this.$router.push('/upcoming'); 
      }
    },
    async getVacantesForUniversity() {
      const resp = await universityService.getVacantesForUniversity({ slug: this.search });
      const { data } = resp.data;
      this.listVacantes = data;
      console.log("aqui 2",this.listVacantes);
    },
    filter(vacantes) {
      if (this.searchInput != "") {
        return (
          vacantes.vacante.titulo.toLowerCase().match(this.searchInput.toLowerCase()) ||
          vacantes.vacante.ubicacion.toLowerCase().match(this.searchInput.toLowerCase()) ||
          vacantes.vacante.modalidad.toLowerCase().match(this.searchInput.toLowerCase()) ||
          vacantes.nameCompany.toLowerCase().match(this.searchInput.toLowerCase())
        );
      } else {
        return vacantes;
      }
    },
    viewregister2() {
      window.location.href = "/signin";
    },
    gohome() {
      window.location.href = "/";
    },
    linkVacante(id) {
      window.location.href = "/vacantes/vista/" + id;
    },
    //TODO: Metodo Ordenar Vacantes fecha
    filterVacantesTFecha(vacantes) {
      vacantes.sort((a, b) => {
        if (a.vacante.createdAt == b.vacante.createdAt) {
          return 0;
        }
        if (a.vacante.createdAt > b.vacante.createdAt) {
          return -1;
        }
        return 1;
      });
      return vacantes;
    },
  },
});
</script>
<style land="scss">
@import "../styles/vacantesPre.scss";
</style>
